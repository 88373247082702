/* eslint-disable indent */
/* eslint-disable react/no-danger */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const QUERY = graphql`
  query {
    siteMetadataJson {
      siteName
      siteDescription
      siteUrl
      socialMedia {
        twitterHandle
        fbAppId
      }
    }
  }
`;

export const PageSEO = ({ title, description, pathname, featureImage, lang }) => {
  //
  const { siteMetadataJson } = useStaticQuery(QUERY);

  const { siteName, siteDescription, siteUrl, socialMedia } = siteMetadataJson;

  const formatImageUrl = (imageRef) => {
    if (imageRef.substring(0, 5) !== 'https') {
      return `${siteUrl}/${imageRef}`;
    }
    return imageRef;
  };

  const formatPageUrl = (pathName) => {
    if (pathName === '/') {
      return `${siteUrl}/`;
    }
    return `${siteUrl}${pathName}`;
  };

  const metaDescription = description || siteDescription;
  const imageUrl = formatImageUrl(featureImage);

  const schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: siteName,
      url: siteUrl,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: title,
      description,
    },
  ];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${siteName}`}
      >
        {/* General tags */}
        <meta name="description" content={metaDescription} />
        <meta name="image" content={imageUrl} />

        {/* OpenGraph tags FB, LinkedIn, Pinterest */}
        <meta property="og:url" content={formatPageUrl(pathname)} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content={1200} />
        <meta property="og:image:height" content={628} />
        <meta property="fb:app_id" content={socialMedia.fbAppId} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={socialMedia.twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>

      {/* Json Ld */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
    </>
  );
};

PageSEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  featureImage: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

PageSEO.defaultProps = {
  description: '',
  lang: 'en',
  featureImage: '',
};
