import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from '../../components/Elements';
import { Promo1p } from '../home/Promo1p';
import { Promo2p } from '../home/Promo2p';
import { Promo3 } from '../home/Promo3';
import { Promo0 } from '../home/Promo0';
import { PromoSplash1 } from '../home/PromoSplash1';
import { PromoSplash2 } from '../home/PromoSplash2';
import { PromoSplash3 } from '../home/PromoSplash3';

const Section = styled.section`
  padding: 4rem 0;

  .section-heading {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .poster-container {
    max-width: 61.25rem;
    margin: 0 auto 2rem;
  }
`;

const Section01 = () => {
  //
  return (
    <Section>
      <Container>
        <h1 className="roboto-text-xl section-heading">Latest Promotion</h1>
        <div className="roboto-text-base roboto-text-base--subtitle section-subtitle">Click image to enlarge.</div>
        <div className="poster-container">
          <a href="/promo-1.jpg" target="_blank">
            <Promo1p />
          </a>
        </div>
        <div className="poster-container">
          <a href="/promo-2.jpg" target="_blank">
            <Promo2p />
          </a>
        </div>
        <div className="poster-container">
          <a href="/promo-3.jpg" target="_blank">
            <Promo3 />
          </a>
        </div>
        <div className="poster-container">
          <a href="/promo-0.jpg" target="_blank">
            <Promo0 />
          </a>
        </div>
        {/* <div className="poster-container">
          <a href="/promo-2.jpg" target="_blank">
            <PromoSplash2 />
          </a>
        </div> */}
      </Container>
    </Section>
  );
};

export default Section01;

Section01.propTypes = {
  //
};

Section01.defaultProps = {
  //
};
