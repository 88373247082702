/* eslint-disable import/prefer-default-export */

import { graphql, useStaticQuery } from 'gatsby';

export const useGoogleReview = () => {
  const {
    allGooglePlacesPlace: { edges: places },
    allGooglePlacesReview: { edges: googleReviews },
  } = useStaticQuery(
    graphql`
      query {
        allGooglePlacesPlace {
          edges {
            node {
              user_ratings_total
              rating
            }
          }
        }
        allGooglePlacesReview(sort: { fields: time, order: DESC }) {
          edges {
            node {
              author_name
              author_url
              id
              profile_photo_url
              rating
              relative_time_description
              text
              time
            }
          }
        }
      }
    `
  );

  const { user_ratings_total: totalReviews, rating } = places[0].node;

  const reviews = googleReviews.map((review) => {
    const localDate = new Date(review.node.time * 1000).toLocaleDateString('en-SG');
    const date = new Date(review.node.time * 1000).toISOString();
    return {
      reviewBody: review.node.text,
      reviewerType: 'Person',
      reviewerName: review.node.author_name,
      datePublished: date,
      ratingValue: review.node.rating,
      worstRating: 0,
      bestRating: 5,
      reviewAspect: 'Service',
      id: review.node.id,
      reviewerImageUrl: review.node.profile_photo_url,
      relativeTimeDescription: review.node.relative_time_description,
      localDate,
    };
  });

  return {
    reviews,
    rating,
    totalReviews,
  };
};
