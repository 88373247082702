/* eslint-disable react/no-danger */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const QUERY = graphql`
  query {
    siteMetadataJson {
      siteName
      siteDescription
      siteUrl
      logo
      socialMedia {
        twitterHandle
        fbAppId
      }
      contact {
        email
        telephone
        address {
          streetAddress
          addressLocality
          addressRegion
          postalCode
          addressCountry
        }
        latitude
        longitude
      }
      business {
        businessName
        serviceType
        dayOfWeek
        opens
        closes
        priceRange
      }
    }
  }
`;

export const RestaurantSEO = ({
  menuTypeName,
  menuTypeDescription,
  menuData,
  cuisines,
  aggregateRating,
  reviews,
  title,
  description,
  pathname,
  featureImage,
  lang,
}) => {
  //
  const { siteMetadataJson } = useStaticQuery(QUERY);
  const { siteName, siteDescription, siteUrl, logo, socialMedia, contact, business } = siteMetadataJson;

  const formatImageUrl = (imageRef) => {
    if (imageRef.substring(0, 5) !== 'https') {
      return `${siteUrl}/${imageRef}`;
    }
    return imageRef;
  };

  const formatPageUrl = (pathName) => {
    if (pathName === '/') {
      return `${siteUrl}/`;
    }
    return `${siteUrl}${pathName}`;
  };

  const metaDescription = description || siteDescription;
  const imageUrl = formatImageUrl(featureImage);
  const logoUrl = formatImageUrl(logo);

  const formattedReviews = reviews.map((review) => {
    return {
      '@context': 'http://schema.org/',
      '@type': 'Review',
      reviewBody: review.reviewBody,
      itemReviewed: {
        '@type': business.serviceType,
        name: business.businessName,
        image: logoUrl,
        telephone: contact.telephone,
        address: {
          '@type': 'PostalAddress',
          streetAddress: contact.address.streetAddress,
          addressLocality: contact.address.addressLocality,
          addressRegion: contact.address.addressRegion,
          postalCode: contact.address.postalCode,
          addressCountry: contact.address.addressCountry,
        },
        priceRange: business.priceRange,
      },
      author: {
        '@type': review.reviewerType,
        name: review.reviewerName,
      },
      datePublished: review.datePublished,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.ratingValue,
        worstRating: review.worstRating,
        bestRating: review.bestRating,
        reviewAspect: review.reviewAspect,
      },
    };
  });

  // Custom logic below to map over incoming menu data

  const {
    menuMainCategoryName,
    menuMainCategoryDescription,
    image: menuMainCategoryImage,
  } = menuData[0].node.menuMainCategory;

  const mainCategoryDescription = menuMainCategoryDescription || menuMainCategoryName;
  const mainCategoryImageUrl =
    (menuMainCategoryImage && `${siteUrl}${menuMainCategoryImage.asset.localFile.publicURL}`) || logoUrl;

  const nestedMenuItems = menuData.map((item) => {
    //
    const subCategoryName = item.node.menuSubCategory
      ? item.node.menuSubCategory.menuSubCategoryName
      : menuMainCategoryName;

    const subCategoryDescription =
      item.node.menuSubCategory && item.node.menuSubCategory.menuSubCategoryDescription
        ? item.node.menuSubCategory.menuSubCategoryDescription
        : subCategoryName;

    const menuItemImageUrl = item.node.image ? `${siteUrl}${item.node.image.asset.localFile.publicURL}` : logoUrl;

    return {
      '@type': 'MenuSection',
      name: item.node.menuSubCategory ? item.node.menuSubCategory.menuSubCategoryName : menuMainCategoryName,
      description: subCategoryDescription,
      image: mainCategoryImageUrl,
      offers: {
        '@type': 'Offer',
        availabilityEnds: business.opens,
        availabilityStarts: business.closes,
      },
      hasMenuItem: {
        '@type': 'MenuItem',
        name: item.node.name,
        description: item.node.description,
        offers: {
          '@type': 'Offer',
          price: item.node.variants[0].variantPrice,
          priceCurrency: 'SGD',
        },
        image: menuItemImageUrl,
      },
    };
  });

  const baseSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      name: siteName,
      url: siteUrl,
    },
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: title,
      description,
    },
  ];

  const restaurantSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'Restaurant',
      name: business.businessName,
      description: metaDescription,
      image: logoUrl,
      telephone: contact.telephone,
      email: contact.email,
      address: {
        '@type': 'PostalAddress',
        streetAddress: contact.address.streetAddress,
        addressLocality: contact.address.addressLocality,
        addressRegion: contact.address.addressRegion,
        postalCode: contact.address.postalCode,
        addressCountry: contact.address.addressCountry,
      },
      openingHoursSpecification: {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: business.dayOfWeek,
        opens: business.opens,
        closes: business.closes,
      },
      url: siteUrl,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: contact.latitude,
        longitude: contact.longitude,
      },
      priceRange: business.priceRange,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: aggregateRating.ratingValue,
        bestRating: aggregateRating.bestRating,
        worstRating: aggregateRating.worstRating,
        ratingCount: aggregateRating.ratingCount,
      },
      review: formattedReviews,
      servesCuisine: [...cuisines],
      hasMenu: {
        '@type': 'Menu',
        name: menuTypeName,
        description: menuTypeDescription,
        hasMenuSection: [
          {
            '@type': 'MenuSection',
            name: menuMainCategoryName,
            description: mainCategoryDescription,
            image: mainCategoryImageUrl,
            offers: {
              '@type': 'Offer',
              availabilityEnds: business.opens,
              availabilityStarts: business.closes,
            },
            hasMenuSection: [...nestedMenuItems],
          },
        ],
      },
    },
  ];

  const schema = [...baseSchema, ...restaurantSchema];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${siteName}`}
      >
        {/* General tags */}
        <meta name="description" content={metaDescription} />
        <meta name="image" content={imageUrl} />

        {/* OpenGraph tags FB, LinkedIn, Pinterest */}
        <meta property="og:url" content={formatPageUrl(pathname)} />
        <meta property="og:site_name" content={siteName} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content={1200} />
        <meta property="og:image:height" content={628} />
        <meta property="fb:app_id" content={socialMedia.fbAppId} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={socialMedia.twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
    </>
  );
};

RestaurantSEO.propTypes = {
  menuTypeName: PropTypes.string.isRequired,
  menuTypeDescription: PropTypes.string.isRequired,
  cuisines: PropTypes.array.isRequired,
  aggregateRating: PropTypes.object.isRequired,
  menuData: PropTypes.array.isRequired,
  reviews: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  featureImage: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

RestaurantSEO.defaultProps = {
  description: '',
  lang: 'en',
  featureImage: '',
};
