/* eslint-disable import/prefer-default-export */

import { graphql, useStaticQuery } from 'gatsby';

export const useSEO = (pathname, subpath) => {
  const {
    allSeoJson: { edges: seo },
  } = useStaticQuery(
    graphql`
      query {
        allSeoJson {
          edges {
            node {
              page
              title
              description
            }
          }
        }
      }
    `
  );

  const normalizePath = (str) => {
    if (str.length === 1 && str === '/') {
      return str;
    }
    if (!subpath) {
      if (str.endsWith('/')) {
        return str.slice(0, -1);
      }
      return str;
    }
    if (subpath) {
      return str.replace(`/${subpath.toLowerCase()}`, '');
    }
    return null;
  };

  const pageSeo = seo.filter((page) => {
    return page.node.page === normalizePath(pathname);
  });

  if (pageSeo[0]) {
    return {
      title: pageSeo[0].node.title,
      description: pageSeo[0].node.description,
    };
  }

  return {
    title: 'Official Site',
    description: '',
  };
};
